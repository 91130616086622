import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useLoggedInUser } from 'api';
import { getUnauthorizedRoutes } from 'components/routing/routes/getUserRoutes';

import Lazy from './LazyComponents';
import { LazyRoute, RouterItem } from './LazyRoute';

type Props = {
  routes: RouterItem[];
};

const AppRouter: FC<Props> = ({ routes }) => {
  const { t } = useTranslation('translation');
  const user = useLoggedInUser();

  const unauthorizedRoutes = getUnauthorizedRoutes(routes);

  return (
    <Switch>
      {routes.map(route => {
        if (route.type === 'route') {
          return (
            <Route
              key={`app-route-${route.path}`}
              exact
              path={route.path}
              render={route.render}
            />
          );
        } else if (route.type === 'redirect') {
          return (
            <Route key={`app-redirect-${route.path}`} exact path={route.path}>
              <Redirect to={route.to} />
            </Route>
          );
        } else {
          throw new Error(`${t('unhandled_route_type')}: ${route}`);
        }
      })}

      {/*Unauthorized routes*/}
      {unauthorizedRoutes.map(route => (
        <Route
          key={`app-unauthorized-${route.path}`}
          exact
          path={route.path}
          render={user ? LazyRoute(Lazy.Unauthorized) : LazyRoute(Lazy.Login)}
        />
      ))}

      {/* Not found route */}
      <Route exact render={LazyRoute(Lazy.NotFound)} />
    </Switch>
  );
};

export default AppRouter;

import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLoggedInUser } from 'api';
import { fetchSettings } from 'api/settingsApi';
import { INSTANCE_FE_SETTINGS_INITIAL_VALUES } from 'modules/settings/user-settings/forms/config';
import { InstanceSettings } from 'utils/types/api';

type InstanceSettingsCtxType = {
  settings: InstanceSettings;
  refetchInstanceSettings: () => void;
};

const defaultValue = {
  settings: {
    feSettings: INSTANCE_FE_SETTINGS_INITIAL_VALUES,
    sendTaskAssignedEmailAutomatically: true,
  },
  refetchInstanceSettings: () => null,
};

export const InstanceSettingsCtx =
  createContext<InstanceSettingsCtxType>(defaultValue);

const useInstanceSettingsContext = () => {
  const [settings, setSettings] = useState({
    feSettings: INSTANCE_FE_SETTINGS_INITIAL_VALUES,
    sendTaskAssignedEmailAutomatically: true,
  });

  const user = useLoggedInUser();

  const refetchInstanceSettings = useCallback(async () => {
    const settingsDTO = await fetchSettings();

    // Frontend settings are null on BE when a new instance is initialized
    if (settingsDTO.feSettings !== null) {
      setSettings({
        feSettings: JSON.parse(settingsDTO.feSettings),
        sendTaskAssignedEmailAutomatically:
          settingsDTO.sendTaskAssignedEmailAutomatically,
      });
    } else {
      setSettings({
        feSettings: INSTANCE_FE_SETTINGS_INITIAL_VALUES,
        sendTaskAssignedEmailAutomatically:
          settingsDTO.sendTaskAssignedEmailAutomatically,
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      refetchInstanceSettings();
    }
  }, [refetchInstanceSettings, user]);

  const ctx: InstanceSettingsCtxType = useMemo(
    () => ({
      settings,
      refetchInstanceSettings,
    }),
    [settings, refetchInstanceSettings],
  );

  return ctx;
};

// Wrapped context provider
export const InstanceSettingsProvider: FC = ({ children }) => {
  const ctx = useInstanceSettingsContext();

  return (
    <InstanceSettingsCtx.Provider value={ctx}>
      {children}
    </InstanceSettingsCtx.Provider>
  );
};

export const useInstanceSettings = () => useContext(InstanceSettingsCtx);

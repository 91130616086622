import menu_and_routes from './menu_and_routes';
import translation from './translation';
import validation_errors from './validation_errors';

const en = {
  menu_and_routes,
  translation,
  validation_errors,
};
export default en;

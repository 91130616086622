import ky from 'ky';
import { createContext, useCallback, useContext } from 'react';
import { useQuery, QueryClient } from 'react-query';

import { ContextUser } from 'utils/types/api';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

export const api = () =>
  ky.extend({
    prefixUrl: '/api/esf',
    timeout: 30000,
    throwHttpErrors: false,
    retry: 0,
    hooks: {
      afterResponse: [
        // Handle session expiration
        async (request, _, response) => {
          if (response.status === 403) {
            await queryClient.invalidateQueries(['me']);
            return ky(request);
          }
          return;
        },
      ],
    },
  });

const UserContext = createContext<ContextUser | undefined>(undefined);

export const useLoggedInUserProvider = () => {
  const userResponse = useQuery(
    'me',
    useCallback(
      () =>
        api()
          .get('me')
          // eslint-disable-next-line
          .json<any>()
          .catch(() => undefined),
      [],
    ),
    {
      staleTime: Infinity,
    },
  );

  return { userResponse, UserContextProvider: UserContext.Provider };
};

export const useLoggedInUser = () => useContext(UserContext);

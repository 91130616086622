import { styled } from '@mui/material';
import { Box } from '@mui/system';

const Wrapper = styled(Box)({});
Wrapper.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: 0,
  p: 3,
};

export default Wrapper;

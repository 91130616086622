export default {
  company: 'firma',
  greetings: 'Ahoj',
  group_one: 'tematická oblast',
  group_few: 'tematické oblasti',
  group_many: 'tematických oblasti',
  question_one: 'otázka',
  question_few: 'otázky',
  question_many: 'otázek',
  name: 'Název',
  questions_number: 'Počet otázek',
  required_questions_number: 'Povinné otázky',
  default_count_required_questions: 'Výchozí počet povinných otázek',
  detail: 'Detail',
  description: 'Popis',
  cancel: 'Zrušit',
  save: 'Uložit',
  delete: 'Smazat',
  group_overview: 'Přehled tematických oblasti',
  manage_questions: 'Spravovat otázky',
  edit: 'Upravit',
  comment_one: 'komentář',
  comment_few: 'komentáře',
  comment_many: 'komentářů',
  new_f: 'Nová',
  new_n: 'Nové',
  new_m: 'Nový',
  display_more: 'Zobrazit více',
  display_less: 'Zobrazit méně',
  settings_questions_heading: 'Otázky',
  settings_groups_heading: 'Tematické oblasti',
  form_prompt_message:
    'Na stránce se mohou nacházet neuložené změny a můžete o ně přijít.',
  role: 'Role',
  email: 'E-mail',
  user_name: 'Jméno',
  active: 'Aktivní',
  yes: 'Ano',
  no: 'Ne',
  my_projects: 'Moje projekty',
  my_tasks: 'Moje úkoly',
  finished: 'Dokončený',
  display_all: 'Zobrazit všechny',
  project: 'Projekt',
  projects: 'Projekty',
  basic_information: 'Základní informace',
  start: 'Začátek',
  end: 'Ukončení',
  owner: 'Zakladatel',
  assigned_to: 'Přiřazeno',
  assign_to_check: 'Komu přiřazujete projekt?',
  task: 'Úkol',
  tasks: 'Úkoly',
  required_field: 'Povinné pole',
  create_project_error: 'Nastala chyba při vytváření projektu.',
  create_project_success: 'Projekt byl úspěšně vytvořen.',
  edit_project_error: 'Nastala chyba při úpravě projektu.',
  edit_project_success: 'Projekt byl úspěšně úpraven.',
  assignee: 'Přiřazená osoba',
  module_no_questions: 'Tento modul zatím neobsahuje žádné tematické oblasti.',
  comments_number: 'Počet komentářů',
  task_name: 'Název úkolu',
  deadline: 'Deadline',
  status: 'Status',
  task_no_questions: 'Tento úkol zatím neobsahuje žádné tematické oblasti.',
  question_groups_left_one: 'Zbývá vyplnit: {{count}} tematická oblast',
  question_groups_left_few: 'Zbývá vyplnit: {{count}} tematické oblasti',
  question_groups_left_many: 'Zbývá vyplnit: {{count}} tematické oblasti',
  create_group_error: 'Nastala chyba při vytváření tematické oblasti.',
  create_group_success: 'Tematická oblast byla úspěšně vytvořena.',
  edit_group_error: 'Nastala chyba při úpravě tematické oblasti.',
  edit_group_success: 'Tematická oblast byla úspěšně upraveno.',
  groups_and_questions: 'Tematické oblasti a otázky',
  users: 'Uživatelé',
  instance: 'Nastavení aplikace',
  warning: 'Varování',
  new_user: 'Nový uživatel',
  save_and_mail: 'Uložit a poslat aktivační e-mail',
  user_deletion_error: 'Nastala chyba při smazání uživatele.',
  user_deletion_success: 'Uživatel byl úspěšně smazán.',
  user_deletion_check: 'Opravdu chcete uživatele smazat?',
  user_activation_mail_check:
    'Opravdu chcete uživateli poslat aktivační e-mail?',
  group_deletion_error: 'Nastala chyba při smazání tematické oblasti.',
  group_deletion_success: 'Tematická oblast byla úspěšně smazáno.',
  group_deletion_check: 'Opravdu chcete tematickou oblast smazat?',
  task_deletion_error: 'Nastala chyba při smazání otázky.',
  task_deletion_success: 'Otázka byla úspěšně smazána.',
  task_deletion_check: 'Opravdu chcete otázku smazat?',
  project_deletion_error: 'Nastala chyba při smazání projektu.',
  project_deletion_success: 'Projekt byl úspěšně smazán.',
  project_deletion_check: 'Opravdu chcete projekt smazat?',
  manage_tasks: 'Spravovat úkoly',
  projects_overview: 'Přehled projektů',
  unknown_user: 'Neznámý uživatel',
  hide_note: 'Skrýt poznámku',
  show_note: 'Zobrazit poznámku',
  my_note: 'Poznámka pro mě',
  hide_comment: 'Skrýt komentáře',
  new_comment: 'Nový komentář',
  comment_send_error: 'Nastala chyba při odesílání komentáře.',
  rating_submit_error: 'Nastala chyba při hodnocení otázky.',
  rating_submit_success: 'Otázky byly úspěšně ohodnoceny.',
  send_rating: 'Odeslat hodnocení',
  questions_done: 'Splněno',
  questions_not_done: 'Nesplněno',
  group_no_questions: 'Tato tematická oblast zatím neobsahuje žádné otázky.',
  show_only_required_questions: 'Zobrazit jen povinné otázky',
  show_optional_questions: 'Odkrýt nepovinné otázky',
  password_recovery_404: 'Účet s takovým e-mailem neexistuje',
  groups: 'Tematické oblasti',
  module: 'Modul',
  task_creation_success: 'Úkol byl úspěšně vytvořen.',
  task_creation_error: 'Nastala chyba při vytváření úkolu.',
  task_edit_success: 'Úkol byl úspěšně upravený',
  task_edit_error: 'Nastala chyba při úpravě úkolu',
  add_delete_error:
    'Nastala chyba při přidávaní {{add}} a při odstraňovaní {{delete}}',
  add_error: 'Nastala chyba při přidávaní {{add}}.',
  delete_error: 'Nastala chyba při odstraňovaní {{delete}}',
  note_saving_error: 'Nastala chyba při uložení poznámky.',
  note_saving_success: 'Poznámka byla úspěšně uložena.',
  note_deletion_error: 'Nastala chyba při smazání poznámky.',
  note_deletion_success: 'Poznámka byla úspěšně smazána.',
  note_deletion_check: 'Opravdu chcete poznámku smazat?',
  invalid_login: 'Nesprávné přihlašovací údaje',
  login: 'Přihlášení',
  password: 'Heslo',
  logging_in: 'Přihlásit se',
  forgotten_password: 'Zapomenuté heslo nebo ztracen aktivační email?',
  recover_password: 'Obnovit heslo',
  passwords_not_match: 'Zadaná hesla se neshodují.',
  invalid_link_recovery: 'Nesprávný odkaz pro obnovu hesla.',
  new_password: 'Nové heslo',
  new_password_again: 'Nové heslo znovu pro kontrolu',
  new_password_create: 'Vytvořit nové heslo',
  new_password_creation: 'Vytvoření nového hesla',
  password_recovery: 'Žádost o vytvoření nového hesla',
  enter_email: 'Zadejte váš e-mail.',
  continue: 'Pokračovat',
  new_account_registration_request: 'Žádost o založení nového firemního účtu',
  company_name: 'Název společnosti',
  note: 'Poznámka',
  send_request: 'Odeslat žádost',
  request_processed: 'Žádost by měla být zpracovaná do jednoho pracovního dne.',
  new_account_registration_btn: 'Požádat o registraci nového firemního účtu',
  all_rights_reserved: 'Všechna práva vyhrazena',
  user_creation_error: 'Nastala chyba při vytváření uživatele.',
  user_creation_success: 'Uživatel byl úspěšně vytvořen.',
  user_edit_error: 'Nastala chyba při úpravě uživatele.',
  user_edit_success: 'Uživatel byl úspěšně upraven.',
  surname: 'Příjmení',
  assigned_roles: 'Přiřazené role',
  role_choice: 'Výběr rolí',
  general_projects: 'Všeobecné',
  evaluation_label: 'V projektu se mi',
  evaluation_label_useful: 'hodilo',
  evaluation_label_useless: 'nehodilo',
  evaluation_label_done: 'Splněno',
  landing_welcome_headline: 'Vítejte ve Wrelay',
  finished_label: 'Dokončený',
  ongoing: 'Probíhající',
  submit: 'Odevzdat',
  mentor: 'Mentor',
  feedback_please: 'Prosím zadejte zpětnou vazbu k úkolu',
  send: 'Odeslat',
  comment_deletion_check: 'Opravdu chcete komentář smazat?',
  add_comment: 'Přidat zpětnou vazbu',
  feedback_saving_success: 'Zpětná vazba úspěšne uložena',
  hr_company_feedback: 'Finální feedback pro firmu',
  hr_assignee_feedback: 'Finální feedback pro nováčka',
  as_hr_feedback: 'Feedback od HR',
  internal_note: 'Interní poznámka',
  close_task: 'Uzavřít úkol',
  close_task_warning:
    'Opravdu chcete uzavřít úkol? Všechno ješte není dokončené.',
  submit_task: 'Odevzdat úkol',
  as_feedback_from: 'Feedback od nováčka',
  mentor_feedback_company:
    'Feedback pro firmu (interní komentář, neuvidí jej nováček)',
  mentor_feedback_from: 'Feedback od mentora',
  mentor_feedback_assignee: 'Feedback pro nováčka',
  organizations: 'Organizace',
  org_edit_success: 'Organizace byla úspěšně upravěna.',
  org_edit_error: 'Nastala chyba při úpravě organizace.',
  accept: 'Přijmout',
  deny: 'Odmítnout',
  deny_sucess: 'Organizace byla úspěšně odmítnuta.',
  accept_success: 'Organizace byla úspěšně schválená',
  delete_org_success: 'Organizace byla úspěšně smazána.',
  deny_error: 'Nastala chyba při odmítnutí organizace.',
  accept_error: 'Nastala chyba při schválováni organizace.',
  delete_org_error: 'Nastala chyba při smazání organizace.',
  org_deletion_check: 'Opravdu si přejete organizaci deaktivovat?',
  PENDING: 'Čekající',
  ACTIVE: 'Aktivní',
  DEACTIVATED: 'Deaktivované',
  deactivate: 'Deaktivovat',
  activate: 'Aktivovat',
  org_activation_check: 'Opravdu si přejete organizaci znovu aktivovat?',
  org_activate_error: 'Nastala chyba při aktivováni organizace.',
  org_activate_success: 'Organizace byla úspěšně aktivováná.',
  unhandled_route_type: 'Nezpracovaný typ cesty',
  onboarding: 'Onboarding',
  for_who_onboarding_check: 'Pro koho onboarding připravujete?',
  question_count: 'Počet otázek',
  required_questions: 'Povinné otázky',
  create_question_error: 'Nastala chyba při vytváření otázky.',
  create_question_success: 'Otázka byla úspěšně vytvořena.',
  edit_question_error: 'Nastala chyba při úpravě otázky.',
  edit_question_success: 'Otázka byla úspěšně upravena.',
  old_web_version: 'Používáte starou verzi webu',
  page_error: 'Na této stránce došlo k chybě',
  old_web_reload:
    'Vaše verze webu není aktuální. Pro vyřešení tohoto problému prosím\n          znovu načtěte stránku.',
  reload: 'Načíst znovu',
  click_error_details: 'Kliknutím zobrazíte podrobnosti o chybě',
  show_all_comments: 'Zobrazit všechny komentáře ({{count}})',
  show_less_comments: 'Zobrazit méně komentářů',
  invalid_value: 'Nesprávna hodnota pole',
  end_date_earlier_than_start_date:
    'Datum ukončení nemůže být dříve než datum začátku',
  type: 'Typ',
  project_name_error: 'Projekt s tímto názvem již existuje.',
  back_home: 'Zpět domů',
  page_not_found: '404, stránka nebyla nalezena',
  page_unauthorized: '401, nemáte oprávnění k přístupu na tuto stránku.',
  deadline_out_of_range: 'Termín je mimo rozsah trvání projektu.',
  ENTITY_NOT_EXIST: 'Uživatel s tímto e-mailem neexistuje.',
  email_exists: 'Uživatel s tímto e-mailem již existuje.',
  recovery_link_expired: 'Platnost odkazu pro obnovení hesla již vypršela.',
  group_one_uppercase_first_letter: 'Skupina',
  end_project: 'Ukončit',
  end_project_check: 'Jste si jisti, že chcete projekt ukončit?',
  force_end_project_success: 'Projekt byl úspěšně ukončen.',
  force_end_project_error: 'Při ukončování projektu došlo k chybě.',
  default_task: 'Obecné tematické oblasti',
  default_task_description:
    'Správa obecných tematických oblastí pro projekt, které nejsou úkol.',
  move_to_topic: 'Přesunout k tematické oblasti',
  visible_modules: 'Viditelné moduly',
  others: 'Ostatní',
  send_email_check: 'Poslat potvrzující email po přiřazení úkolu?',
  instance_settings: 'Nastavení aplikace',
  instance_settings_update_success: 'Nastavení byla úspěšně aktualizována.',
  instance_settings_update_error: 'Aktualizace nastavení se nezdařila.',
  one_module_must_be_visible: 'Alespoň jeden modul musí být viditelný.',
  end_project_without_feedback: 'Ukončit úkol bez feedbacku',
  force_end_task_success: 'Úkol byl úspěšně ukončen.',
  force_end_task_error: 'Při ukončování úkolu došlo k chybě.',
  fillin: 'Vyplnit',
  required_questions_tooltip:
    'Jde o počet otázek, které musí uživatel zodpovědět (pokud jich tolik už v databázi je), aby měl úkol splněn. Půjde o otázky, které jsou nejlépe hodnoceny předchozími uživateli.',
  rating_tooltip: 'Hodnocení je možné odeslat až po vyplnění povinných otázek.',
  partially_saved: 'Průběžně uloženo.',
  partially_saved_error: 'Průběžné uložení selhalo.',
  partially_save: 'Průběžně uložit',
  deleted_rating: 'Hodnocení úspěšně smazáno.',
  delete_rating_failed: 'Vymazání hodnocení selhalo.',
  feedback: 'Feedback',
  add_user: 'Přidat uživatele',
  unfinished: 'Nedokončený',
  groups_tooltip:
    'Znalosti jsou rozděleny do témat. Témata jsou například: "Obecné k projektům", "Rozpočty", "Externí projekty", "SW projekty".',
  date_tooltip: 'Zvolte datum, které je dříve, než den ukončení projektu.',
  choose_module: 'Vyberte modul',
  group_settings_info:
    'Jednotlivé znalosti jsou u každého tématu ve formě otázek, aby se s nimi uživatelům snadněji pracovalo. Jde vlastně o check-list. Např.: "Máte v rozpočtu zahrnuto občerstvení na akce?" "Je program akce odsouhlasen managementem?"',
  tasks_settings_info:
    'Můžete úkoly jen očíslovat, nebo odlišit názvem. Např. úkol "Zkontrolovat před projektem" nebo jen "Check-list k projektu".',
  help: 'Nápověda',
};

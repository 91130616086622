export default {
  dashboard: 'Dashboard',
  projects: 'Projekty',
  project_detail: 'Detail projektu',
  task_detail: 'Detail úkolu',
  risks: 'Rizika',
  stakeholders: 'Stakeholdeři',
  lessons_learned: 'Lessons learned',
  onboarding: 'Onboarding',
  settings: 'Správa',
  login: 'Přihlášení',
  settings_groups: 'Tematické oblasti',
  settings_groups_questions: 'Otázky',
  settings_users: 'Uživatelé',
  settings_instance: 'Nastavení aplikace',
  settings_projects: 'Projekty',
  settings_tasks: 'Úkoly',
  settings_general_projects: 'Všeobecné',
  settings_onboarding_projects: 'Onboarding',
  admin: 'Správa organizací',
};

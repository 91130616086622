import cz from '../cz/validation_errors';

const en: typeof cz = {
  required: 'Required field.',
  num_max: 'Maximal value is {{max}}.',
  str_max: 'Must contain at most {{count}} characters.',
  array_min: 'Must contain at least {{count}} items',
  invalid_email: 'E-mail has to be in a valid format',
  num_min: 'Minimal value is {{min}}.',
};

export default en;

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { queryClient } from 'api';
import ErrorBoundary from 'modules/common/ErrorBoundary';

import App from './App';

// Toastify styles
import 'react-toastify/dist/ReactToastify.min.css';

// Font
import 'assets/font/stylesheet.css';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);

import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createTheme, CssBaseline } from '@mui/material';
import { csCZ, enUS } from '@mui/material/locale';
import { ThemeProvider } from '@mui/material/styles';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';

import { useLoggedInUserProvider } from 'api';
import { QuestionsProvider } from 'components/questions/context/QuestionsContext';
import AppRouter from 'components/routing/AppRouter';
import { getUserRoutes } from 'components/routing/routes/getUserRoutes';
import { InstanceSettingsProvider } from 'components/settings/context/InstanceSettingsContext';
import ErrorPage from 'modules/common/ErrorPage';
import LoadingPage from 'modules/common/LoadingPage';
import { useErrorLocale } from 'utils/localization';
import { theme } from 'utils/theme';
import 'react-quill/dist/quill.snow.css';

const App: FC = () => {
  // User
  const { userResponse, UserContextProvider } = useLoggedInUserProvider();

  const user = useMemo(
    () =>
      userResponse.data
        ? {
            id: userResponse.data.id,
            name: userResponse.data.name,
            email: userResponse.data.email,
            username: userResponse.data.username,
            authorities: userResponse.data.authorities,
            tenant: userResponse.data.tenant,
          }
        : undefined,
    [userResponse],
  );

  // Routes
  const routes = useMemo(() => getUserRoutes(user?.authorities), [user]);

  const { i18n } = useTranslation('menu_and_routes');

  const localizedTheme = useMemo(
    () => createTheme(theme, i18n.language === 'cz' ? csCZ : enUS),
    [i18n.language],
  );

  useErrorLocale();

  return (
    <ThemeProvider theme={localizedTheme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <UserContextProvider value={user}>
          <InstanceSettingsProvider>
            <QuestionsProvider>
              <CssBaseline />

              {userResponse.isLoading && <LoadingPage height="100vh" />}
              {userResponse.isError && (
                <ErrorPage height="100vh" response={userResponse} />
              )}
              {userResponse.isSuccess && <AppRouter routes={routes} />}

              <ReactQueryDevtools />

              <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </QuestionsProvider>
          </InstanceSettingsProvider>
        </UserContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;

import cz from '../cz/menu_and_routes';

const en: typeof cz = {
  dashboard: 'Dashboard',
  projects: 'Projects',
  project_detail: 'Project detail',
  task_detail: 'Task detail',
  risks: 'Risks',
  stakeholders: 'Stakeholders',
  lessons_learned: 'Lessons learned',
  onboarding: 'Onboarding',
  settings: 'Administration',
  login: 'Login',
  settings_groups: 'Topics',
  settings_groups_questions: 'Questions',
  settings_users: 'Users',
  settings_instance: 'Instance',
  settings_projects: 'Projects',
  settings_tasks: 'Tasks',
  settings_general_projects: 'General',
  settings_onboarding_projects: 'Onboarding',
  admin: 'Organizations management',
};

export default en;

import Wrapper from 'components/layout/Wrapper';
import LoadingCircle from 'components/loading/LoadingCircle';

type Props = {
  height: string | number;
};

const LoadingPage = ({ height }: Props) => {
  return (
    <Wrapper height={height} justifyContent="center" alignItems="center">
      <LoadingCircle />
    </Wrapper>
  );
};

export default LoadingPage;

import { EASMultilanguage } from 'utils/types/EASTypes';
import {
  QuestionRating,
  QuestionRatings,
  UserPermissions,
} from 'utils/types/api';

import { Language, Modules, TaskStatuses } from './api';

export const LocalizationToLanguage: Record<string, Language> = {
  cz: 'CZECH',
  en: 'ENGLISH',
};

export const USER_PERMISSIONS: UserPermissions[] = [
  'ADMIN',
  'NEWBIE',
  'ONBOARDING_USER',
  'RISKS_MODERATOR',
  'STAKEHOLDERS_MODERATOR',
  'LESSONS_LEARNED_MODERATOR',
  'ONBOARDING_MODERATOR',
  'RISKS_USER',
  'STAKEHOLDERS_USER',
  'LESSONS_LEARNED_USER',
];

export const UserPermissionsName: Record<UserPermissions, EASMultilanguage> = {
  SUPER_ADMIN: {
    ENGLISH: 'Super Admin',
    CZECH: 'Super Admin',
  } as EASMultilanguage,
  ADMIN: {
    ENGLISH: 'Admin',
    CZECH: 'Admin',
  } as EASMultilanguage,
  NEWBIE: {
    ENGLISH: 'Newbie',
    CZECH: 'Nováček',
  } as EASMultilanguage,
  RISKS_MODERATOR: {
    ENGLISH: 'Risks Moderator',
    CZECH: 'Rizika moderátor',
  } as EASMultilanguage,
  RISKS_USER: {
    ENGLISH: 'Risks User',
    CZECH: 'Rizika uživatel',
  } as EASMultilanguage,
  STAKEHOLDERS_MODERATOR: {
    ENGLISH: 'Stakeholders Moderator',
    CZECH: 'Stakeholdeři moderátor',
  } as EASMultilanguage,
  STAKEHOLDERS_USER: {
    ENGLISH: 'Stakeholders User',
    CZECH: 'Stakeholdeři uživatel',
  } as EASMultilanguage,
  LESSONS_LEARNED_MODERATOR: {
    ENGLISH: 'Lessons Learned Moderator',
    CZECH: 'Lessons learned moderátor',
  } as EASMultilanguage,
  LESSONS_LEARNED_USER: {
    ENGLISH: 'Lessons Learned User',
    CZECH: 'Lessons learned uživatel',
  } as EASMultilanguage,
  ONBOARDING_MODERATOR: {
    ENGLISH: 'Onboarding Moderator',
    CZECH: 'Onboarding moderátor',
  } as EASMultilanguage,
  ONBOARDING_USER: {
    ENGLISH: 'Mentor',
    CZECH: 'Mentor',
  } as EASMultilanguage,
};

export const MODULES: Modules[] = [
  'RISKS',
  'STAKEHOLDERS',
  'LESSONS_LEARNED',
  'ONBOARDING',
];

// Undefined because some roles are not related to any module
export const RoleModule: Record<UserPermissions, Modules | undefined> = {
  RISKS_MODERATOR: 'RISKS',
  RISKS_USER: 'RISKS',
  LESSONS_LEARNED_MODERATOR: 'LESSONS_LEARNED',
  LESSONS_LEARNED_USER: 'LESSONS_LEARNED',
  STAKEHOLDERS_MODERATOR: 'STAKEHOLDERS',
  STAKEHOLDERS_USER: 'STAKEHOLDERS',
  ONBOARDING_MODERATOR: 'ONBOARDING',
  ONBOARDING_USER: 'ONBOARDING',
  SUPER_ADMIN: undefined,
  NEWBIE: undefined,
  ADMIN: undefined,
};

export const ModulesName: Record<Modules, EASMultilanguage> = {
  RISKS: {
    ENGLISH: 'Risks',
    CZECH: 'Rizika',
  } as EASMultilanguage,
  STAKEHOLDERS: {
    ENGLISH: 'Stakeholders',
    CZECH: 'Stakeholdeři',
  } as EASMultilanguage,
  LESSONS_LEARNED: {
    ENGLISH: 'Lessons Learned',
    CZECH: 'Lessons learned',
  } as EASMultilanguage,
  ONBOARDING: {
    ENGLISH: 'Onboarding',
    CZECH: 'Onboarding',
  } as EASMultilanguage,
};

export const TASK_STATUSES: TaskStatuses[] = ['UNFINISHED', 'FINISHED'];

export const TaskStatusesName: Record<TaskStatuses, EASMultilanguage> = {
  UNFINISHED: {
    ENGLISH: 'Active',
    CZECH: 'Aktivní',
  } as EASMultilanguage,
  SUBMITTED: {
    ENGLISH: 'Submitted',
    CZECH: 'Odevzdaný',
  },
  FINISHED: {
    ENGLISH: 'Finished',
    CZECH: 'Dokončený',
  } as EASMultilanguage,
};

export const QuestionRatingToQuestionRatings: Record<
  QuestionRating,
  QuestionRatings
> = {
  0: 'USELESS',
  1: 'USEFUL',
};

import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#2196f3',
    dark: '#1970b5',
    light: '#e3f2fd',
  },
  secondary: {
    main: '#4f4f4f',
  },
  warning: {
    main: '#ff8800',
    light: '#ffce8c',
  },
  success: {
    main: '#14981c',
    light: '#b0ffb3',
  },
  text: {
    primary: '#212121',
    secondary: '#666666',
  },
  lightGrey: '#e5e5e5',
  backgroundGrey: '#fdfdfe',
  spinnerGreen: '#29cc97',
} as const;

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: `Mulish Regular`,
    h1: {
      fontFamily: `Mulish SemiBold`,
      fontSize: 48,
    },
    h2: {
      fontFamily: `Mulish Bold`,
      fontSize: 39,
    },
    h3: {
      fontFamily: `Mulish Bold`,
      fontSize: 31,
    },
    h4: {
      fontFamily: `Mulish Bold`,
      fontSize: 25,
    },
    h5: {
      fontFamily: `Mulish SemiBold`,
      fontSize: 20,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          padding: '8px 8px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          ':hover': {
            backgroundColor: palette.primary.light,
          },
          '&.Mui-selected': {
            color: palette.primary.main,
            backgroundColor: palette.primary.light,
            '& .MuiListItemIcon-root': {
              color: palette.primary.main,
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':before': {
            height: 0,
          },
          border: `1px solid ${palette.lightGrey}`,
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    // MuiInput: {
    //   styleOverrides: {
    //     root: {
    //       ':before': {
    //         border: 0,
    //       },
    //     },
    //   },
    // },
  },
});

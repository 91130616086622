import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import Quill from 'quill';

import { ContextUser, User } from 'utils/types/api';

export const retryPromise = <T>(
  fn: () => Promise<T>,
  retriesLeft = 4,
  interval = 250,
) => {
  return new Promise<T>((resolve, reject) => {
    return fn()
      .then(resolve)
      .catch(error => {
        if (retriesLeft === 1) {
          reject(error);
          return;
        }

        setTimeout(() => {
          // Passing on "reject" is the important part
          retryPromise(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

/**
 * Return sliced string and if slice was applied,
 * also append `...` if slice is applied.
 * @param text input string of any length.
 * @param maxLen maximum lenght of the string to return.
 */
export const previewText = (text: string, maxLen: number) =>
  text.length > maxLen ? text.slice(0, maxLen) + '...' : text;

export const pluralRules = (
  count: number,
  options: { one: string; few: string; many: string },
  showCount = true,
) => {
  switch (Math.abs(count)) {
    case 1:
      return showCount ? `${count} ${options.one}` : options.one;
    case 2:
    case 3:
    case 4:
      return showCount ? `${count} ${options.few}` : options.few;
    default:
      return showCount ? `${count} ${options.many}` : options.many;
  }
};

// https://github.com/quilljs/quill/issues/3188
// https://github.com/zenoamaro/react-quill/issues/529
const Link = Quill.import('formats/link');
Link.sanitize = function (url: string) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};

/**
 * Sanitizes HTML and prevents XSS attacks.
 * @param val input string
 * @returns sanitized string
 */
export const sanitizeRichText = (val: string) =>
  DOMPurify.sanitize(val, {
    // Allow YT embed
    ADD_TAGS: ['iframe'],
    ADD_ATTR: [
      'allow',
      'allowfullscreen',
      'frameborder',
      'scrolling',
      'style',
      'width',
      'height',
      'class',
      'target',
    ],
  });

/**
 * When RT is created, then saved, then edited and the text removed,
 * '<p><br></p>' still stays there and yup does not recognize that
 * the field is empty, thus failing `required` validation.
 */
export const EMPTY_RICH_TEXT = '<p><br></p>';

export const removeTimezoneOffset = (date: Date): Date => {
  const newDate = new Date(date);
  // Date constructor automatically adds time zone offset, we need to remove it
  // to match the date in backend. Might not always work.
  const offset = newDate.getTimezoneOffset() * 60000;
  return new Date(newDate.getTime() + offset);
};

export const addTimezoneOffset = (date: Date): Date => {
  const newDate = new Date(date);
  // Sending requests automatically removes time zone offset, we need to add it
  // to match the date in backend. Might not always work.
  const offset = newDate.getTimezoneOffset() * 60000;
  return new Date(newDate.getTime() - offset);
};

/**
 * Returns the input date in the format DD/MM/YYYY
 * @param date
 */
export const formatDate = (date: Date) => format(new Date(date), 'dd/MM/RRRR');

export const isUserNewbie = (user: ContextUser) =>
  user.authorities.map(a => a.authority).includes('NEWBIE') &&
  !user.authorities.map(a => a.authority).includes('ADMIN') &&
  !user.authorities.map(a => a.authority).includes('SUPER_ADMIN');

export const isUserDTONewbie = (user: User) =>
  user.permissions.includes('NEWBIE') &&
  !user.permissions.includes('ADMIN') &&
  !user.permissions.includes('SUPER_ADMIN');

export const isUserAdmin = (user: ContextUser) =>
  user.authorities.map(a => a.authority).includes('ADMIN');

import { api } from 'api/index';
import { InstanceSettings, InstanceSettingsDTO } from 'utils/types/api';

export const fetchSettings = () =>
  api().get(`instance-settings`).json<InstanceSettingsDTO>();

export const updateSettings = (settings: InstanceSettings) =>
  api().put(`instance-settings`, {
    json: {
      feSettings: JSON.stringify(settings.feSettings),
      sendTaskAssignedEmailAutomatically:
        settings.sendTaskAssignedEmailAutomatically,
    },
  });

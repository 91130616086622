import menu_and_routes from './menu_and_routes';
import translation from './translation';
import validation_errors from './validation_errors';

const cz = {
  menu_and_routes,
  validation_errors,
  translation,
};
export default cz;

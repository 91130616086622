import { lazy, Suspense } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RouteProps, RedirectProps } from 'react-router-dom';

import LoadingPage from 'modules/common/LoadingPage';
import { retryPromise } from 'utils';

export type RouteItem = {
  name: string;
  type: 'route';
  path: string;
} & Required<Pick<RouteProps, 'render'>>;
export type RedirectItem = {
  name: string;
  type: 'redirect';
  path: string;
} & Required<Pick<RedirectProps, 'to'>>;

export type RouterItem = RouteItem | RedirectItem;

// Lazy wrapper with Loading
export const LazyRoute =
  (
    component: Parameters<typeof lazy>[0],
    componentProps?: Record<string, unknown>,
  ) =>
  // eslint-disable-next-line react/display-name
  (routeProps: RouteComponentProps) => {
    const Component = lazy(() => retryPromise(component));
    return (
      <Suspense fallback={<LoadingPage height="100vh" />}>
        <Component {...routeProps} {...componentProps} />
      </Suspense>
    );
  };

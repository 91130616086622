import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { QuestionRatings } from 'utils/types/api';

export type RatedQuestionType = { id: string; rating: QuestionRatings };

type QuestionsCtxType = {
  isTaskGroups: boolean;
  setIsTaskGroups: Dispatch<SetStateAction<boolean>>;
  ratedQuestions: RatedQuestionType[];
  setRatedQuestions: Dispatch<SetStateAction<RatedQuestionType[]>>;
  alreadyRatedQuestions: RatedQuestionType[];
  setAlreadyRatedQuestions: Dispatch<SetStateAction<RatedQuestionType[]>>;
};

export const QuestionsCtx = createContext<QuestionsCtxType>(undefined as never);

const useQuestionsContext = () => {
  const [isTaskGroups, setIsTaskGroups] = useState<boolean>(true);

  // Question which are selected but are not on the server, they are before POST.
  const [ratedQuestions, setRatedQuestions] = useState<RatedQuestionType[]>([]);

  // All questions which were rated and are stored on server.
  const [alreadyRatedQuestions, setAlreadyRatedQuestions] = useState<
    RatedQuestionType[]
  >([]);

  const ctx: QuestionsCtxType = useMemo(
    () => ({
      isTaskGroups,
      setIsTaskGroups,
      ratedQuestions,
      setRatedQuestions,
      alreadyRatedQuestions,
      setAlreadyRatedQuestions,
    }),
    [
      isTaskGroups,
      setIsTaskGroups,
      ratedQuestions,
      setRatedQuestions,
      alreadyRatedQuestions,
      setAlreadyRatedQuestions,
    ],
  );

  return ctx;
};

// Wrapped context provider
export const QuestionsProvider: FC = ({ children }) => {
  const ctx = useQuestionsContext();

  return <QuestionsCtx.Provider value={ctx}>{children}</QuestionsCtx.Provider>;
};

export const useQuestions = () => useContext(QuestionsCtx);

import { Refresh } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Wrapper from 'components/layout/Wrapper';

type Props = {
  children: React.ReactNode;
} & WithTranslation;

type State = {
  error?: Error;
  eventId?: string;
  errorInfo?: React.ErrorInfo;
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: undefined,
    eventId: undefined,
    errorInfo: undefined,
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error({
      error: JSON.stringify(error, null, 4),
      errorInfo: JSON.stringify(errorInfo, null, 4),
    });
  }

  render() {
    const { hasError, errorInfo, error } = this.state;
    const { children, t } = this.props;

    if (!hasError) {
      return children;
    }

    // Chunk load error often occurs when new version of the app is accessed from outdated session
    const isChunkLoadError = !!error?.message.match(
      /^Loading chunk .+ failed$/,
    );

    return (
      <Wrapper justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h2">
          {isChunkLoadError ? t('old_web_version') : t('page_error')}
        </Typography>

        <Typography variant="body1">
          {isChunkLoadError ? t('old_web_reload') : error?.message}
        </Typography>

        <Button
          variant="outlined"
          startIcon={<Refresh />}
          onClick={() => window.location.reload()}
          sx={{ mt: 2 }}
        >
          {t('reload')}
        </Button>

        {errorInfo && (
          <details>
            <summary>{t('click_error_details')}</summary>
            <pre>{errorInfo?.componentStack.toString()}</pre>
          </details>
        )}
      </Wrapper>
    );
  }
}

export default withTranslation()(ErrorBoundary);

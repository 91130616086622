import cz from '../cz/translation';

const en: typeof cz = {
  company: 'company',
  greetings: 'Hello',
  group_one: 'topic',
  group_few: 'topics',
  group_many: 'topics',
  question_one: 'question',
  question_few: 'questions',
  question_many: 'questions',
  name: 'Name',
  questions_number: 'Number of questions',
  required_questions_number: 'Required questions',
  default_count_required_questions: 'Default count of required questions',
  detail: 'Detail',
  description: 'Description',
  cancel: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  group_overview: 'Topics overview',
  manage_questions: 'Manage questions',
  edit: 'Edit',
  comment_one: 'comment',
  comment_few: 'comments',
  comment_many: 'comments',
  new_f: 'New',
  new_n: 'New',
  new_m: 'New',
  display_more: 'Display more',
  display_less: 'Display less',
  settings_questions_heading: 'Questions',
  settings_groups_heading: 'Topics',
  form_prompt_message:
    'Are you sure you want to leave? You have with unsaved changes.',
  role: 'Role',
  email: 'E-mail',
  user_name: 'Name',
  active: 'Active',
  yes: 'Yes',
  no: 'No',
  my_projects: 'My projects',
  my_tasks: 'My tasks',
  finished: 'finished',
  display_all: 'Display all',
  project: 'Project',
  projects: 'Projects',
  basic_information: 'Basic information',
  start: 'Start',
  end: 'End',
  owner: 'Founder',
  assigned_to: 'Assigned to',
  assign_to_check: 'Who do you assign project to?',
  task: 'Task',
  tasks: 'Tasks',
  required_field: 'Required field',
  create_project_error: 'An error occured during project creation.',
  create_project_success: 'Project successfully created.',
  edit_project_error: 'An error occured during project edit.',
  edit_project_success: 'Project was successfully edited.',
  assignee: 'Assigned person',
  module_no_questions: "This module doesn't contain any topics yet.",
  comments_number: 'Number of comments',
  task_name: 'Task name',
  deadline: 'Deadline',
  status: 'Status',
  task_no_questions: "This task doesn't contain any topics yet.",
  question_groups_left_one: 'Left to fill: {{count}} topics',
  question_groups_left_few: 'Left to fill: {{count}} topics',
  question_groups_left_many: 'Left to fill: {{count}} topics',
  create_group_error: 'An error occured during topic creation.',
  create_group_success: 'Topic successfully created.',
  edit_group_error: 'An error occured during topic editation.',
  edit_group_success: 'Topic was successfully edited.',
  groups_and_questions: 'Topics and questions',
  users: 'Users',
  instance: 'App settings',
  warning: 'Warning',
  new_user: 'New user',
  save_and_mail: 'Save and send activation e-mail',
  user_deletion_error: 'An error occurred during user deletion.',
  user_deletion_success: 'User successfully deleted.',
  user_deletion_check: 'Do you really want to delete the user?',
  user_activation_mail_check:
    'Do you really want to send the user an activation e-mail?',
  group_deletion_error: 'An error occurred during topic deletion.',
  group_deletion_success: 'Topic successfully deleted.',
  group_deletion_check: 'Do you really want to delete the topic?',
  task_deletion_error: 'An error occurred during task deletion.',
  task_deletion_success: 'Task successfully deleted.',
  task_deletion_check: 'Do you really want to delete the task?',
  project_deletion_error: 'An error occurred during project deletion.',
  project_deletion_success: 'Project successfully deleted.',
  project_deletion_check: 'Do you really want to delete the project?',
  manage_tasks: 'Manage tasks',
  projects_overview: 'Projects overview',
  unknown_user: 'Unknown user',
  hide_note: 'Hide note',
  show_note: 'Display note',
  my_note: 'My note',
  hide_comment: 'Hide comments',
  new_comment: 'New comment',
  comment_send_error: 'An error occurred during comment submission.',
  rating_submit_error: 'An error occurred during question rating submission.',
  rating_submit_success: 'Questions successfully rated.',
  send_rating: 'Send rating',
  questions_done: 'Done',
  questions_not_done: 'Not done',
  group_no_questions: "This topic doesn't contain any questions yet.",
  show_only_required_questions: 'Display only required questions.',
  show_optional_questions: 'Display optional questions.',
  password_recovery_404: 'An account with this e-mail does not exist.',
  groups: 'Topics',
  module: 'Module',
  task_creation_success: 'Task was successfully created.',
  task_creation_error: 'An error occurred during task creation.',
  task_edit_success: 'Task was successfully edited.',
  task_edit_error: 'An error occurred during the task editation.',
  add_delete_error:
    'An error occurred during the addition of {{add}} and during the deletion of {{delete}}.',
  add_error: 'An error occurred during the addition of {{add}}.',
  delete_error: 'An error occurred during the deletion of {{delete}}.',
  note_saving_error: 'An error occurred during note saving.',
  note_saving_success: 'Note was successfully saved.',
  note_deletion_error: 'An error occurred during note deletion.',
  note_deletion_success: 'Note was successfully deleted.',
  note_deletion_check: 'Are you sure you want to delete the note?',
  invalid_login: 'Invalid login information',
  login: 'Login',
  password: 'Password',
  logging_in: 'Sign in',
  forgotten_password: 'Forgotten password or activation email lost?',
  recover_password: 'Recover password',
  passwords_not_match: "Passwords don't match.",
  invalid_link_recovery: 'Invalid link for password recovery.',
  new_password: 'New password',
  new_password_again: 'Repeat new password',
  new_password_create: 'Create new password',
  new_password_creation: 'New password creation',
  password_recovery: 'Request new password',
  enter_email: 'Enter your e-mail.',
  continue: 'Continue',
  new_account_registration_request: 'Request new company account registration',
  company_name: 'Company name',
  note: 'Note',
  send_request: 'Send request',
  request_processed: 'Request should be processed withing one work day.',
  new_account_registration_btn: 'Request new company account registration',
  all_rights_reserved: 'All rights reserved',
  user_creation_error: 'An error occurred during user creation.',
  user_creation_success: 'User was successfully created.',
  user_edit_error: 'An error occurred during user edition.',
  user_edit_success: 'User was successfully edited.',
  surname: 'Surname',
  assigned_roles: 'Assigned roles',
  role_choice: 'Role choice',
  general_projects: 'General',
  evaluation_label: 'I found it in this project',
  evaluation_label_useful: 'useful',
  evaluation_label_useless: 'useless',
  evaluation_label_done: 'Completed',
  landing_welcome_headline: 'Welcome at Wrelay',
  finished_label: 'Finished',
  ongoing: 'Ongoing',
  submit: 'Submit',
  mentor: 'Mentor',
  feedback_please: 'Please give us feedback on the task',
  send: 'Send',
  comment_deletion_check: 'Do you really want to delete the comment?',
  add_comment: 'Add feedback',
  feedback_saving_success: 'Feedback successfully saved',
  hr_company_feedback: 'Final feedback for company',
  hr_assignee_feedback: 'Final feedback for newbie',
  as_hr_feedback: 'HR feedback',
  internal_note: 'Internal note',
  close_task: 'Close task',
  close_task_warning:
    'Do you really wish to close the task? It is not marked as done yet.',
  submit_task: 'Submit task',
  as_feedback_from: 'Feedback from assignee',
  mentor_feedback_company:
    'Progress feedback for company (internal comment, not visible for newbie)',
  mentor_feedback_from: 'Feedback from mentor',
  mentor_feedback_assignee: 'Feedback for assignee',
  organizations: 'Organization',
  org_edit_success: 'Organization was successfully edited.',
  org_edit_error: 'An error occurred during organization editation.',
  accept: 'Accept',
  deny: 'Deny',
  deny_sucess: 'Organization was successfully declined.',
  accept_success: 'Organization was successfully approved.',
  delete_org_success: 'Organization was successfully deleted.',
  deny_error: 'An error occurred during organization denial.',
  accept_error: 'An error occurred during organization approval.',
  delete_org_error: 'An error occurred during organization deletion.',
  org_deletion_check: 'Do you really want to disable the organization?',
  PENDING: 'Pending',
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  deactivate: 'Deactivate',
  activate: 'Activate',
  org_activation_check: 'Do you really want to activate the organization?',
  org_activate_error: 'An error occurred during organization activation.',
  org_activate_success: 'Organization was successfully activated.',
  unhandled_route_type: 'Unhandled route type',
  onboarding: 'Onboarding',
  for_who_onboarding_check: 'Who do you make the onboarding for?',
  question_count: 'Question count',
  required_questions: 'Required questions',
  create_question_error: 'An error occurred during question activation.',
  create_question_success: 'Question was successfully created.',
  edit_question_error: 'An error occurred during question editation.',
  edit_question_success: 'Question was successfully edited.',
  old_web_version: 'You are using and older version of web',
  page_error: 'An error occured on this page',
  old_web_reload:
    'Your web verion is not up to date. In order to solve this problem please reload this page.',
  reload: 'Reload',
  click_error_details: 'Click for more details about the error.',
  show_all_comments: 'Show all comments ({{count}})',
  show_less_comments: 'Show less comments',
  invalid_value: 'Invalid value',
  end_date_earlier_than_start_date:
    'End date cannot be earlier than start date',
  type: 'Type',
  project_name_error: 'Project with this name already exists.',
  back_home: 'Back home',
  page_not_found: '404, page not found',
  page_unauthorized: '401, you are not authorized to access this page.',
  ENTITY_NOT_EXIST: 'User with this email does not exist.',
  email_exists: 'User with this email already exists.',
  recovery_link_expired: 'Password recovery link has already expired.',
  deadline_out_of_range: 'Deadline is out of the project duration range.',
  group_one_uppercase_first_letter: 'Group',
  end_project: 'Terminate',
  end_project_check: 'Are you sure you want to terminate the project?',
  force_end_project_success: 'The project was successfully terminated.',
  force_end_project_error: 'Project termination failed.',
  default_task: 'General topics',
  default_task_description:
    'Administration of general topics for the project, which are not a task.',
  move_to_topic: 'Move to topic',
  visible_modules: 'Visible modules',
  others: 'Others',
  send_email_check: 'Send confirmation email after task assignment?',
  instance_settings: 'App settings',
  instance_settings_update_success: 'Settings were successfully updated.',
  instance_settings_update_error: 'Settings update failed.',
  one_module_must_be_visible: 'At least one module must be visible.',
  end_project_without_feedback: 'End project without feedback',
  force_end_task_success: 'The task was successfully terminated.',
  force_end_task_error: 'Task termination failed.',
  fillin: 'Fill in',
  required_questions_tooltip:
    'It is the number of questions, which the user has to answer (if there is such count in the database), in order for the task to be marked as completed. It will be the questions, which have the most positive rating by previous users.',
  rating_tooltip:
    'It is possible to rate only after answering the required questions.',
  partially_saved: 'Partially saved.',
  partially_saved_error: 'Partial saving failed.',
  partially_save: 'Partially save',
  deleted_rating: 'Rating successfully deleted.',
  delete_rating_failed: 'Rating deletion failed.',
  feedback: 'Feedback',
  add_user: 'Add user',
  unfinished: 'Unfinished',
  groups_tooltip:
    'Knowledge is divided into groups, i. e. : "General projects", "Budget", "External projects", "SW projects".',
  date_tooltip: 'Select a date before the project deadline.',
  choose_module: 'Choose module',
  group_settings_info:
    'Individual knowledge are in each group in the form o questions, so it\'s easier for the users to work with them. It is a checklist. Examples: "Do you have food expenses included in your budget?" "Was the schedule approved by management?"',
  tasks_settings_info:
    'You can enumerate the tasks, or just differentiate them by name. Examples: "Check before project" or just "Check-list for project".',
  help: 'Help',
};

export default en;

import { UserPermissions } from 'utils/types/api';

export const USER_FORM_INITIAL_VALUES = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  permissions: [] as UserPermissions[],
};

export const INSTANCE_FE_SETTINGS_INITIAL_VALUES = {
  showRisks: true,
  showLessonsLearned: true,
  showStakeholders: true,
  showOnboarding: true,
  defaultQuestionShownCount: 5,
};

import Lazy from '../LazyComponents';
import { LazyRoute, RouterItem } from '../LazyRoute';

export const PUBLIC_ROUTES: RouterItem[] = [
  {
    type: 'route',
    name: 'home',
    path: '/',
    render: LazyRoute(Lazy.Landing),
  },
  {
    type: 'route',
    name: 'registration',
    path: '/registration',
    render: LazyRoute(Lazy.Registration),
  },
  {
    type: 'route',
    name: 'registration-successful',
    path: '/registration-successful',
    render: LazyRoute(Lazy.RegistrationSuccessful),
  },
  {
    type: 'route',
    name: 'password-recovery',
    path: '/password-recovery',
    render: LazyRoute(Lazy.PasswordRecovery),
  },
  {
    type: 'route',
    name: 'password-recovery-successful',
    path: '/password-recovery-successful',
    render: LazyRoute(Lazy.PasswordRecoverySuccessful),
  },
  {
    type: 'route',
    name: 'new-password',
    path: '/new-password',
    render: LazyRoute(Lazy.NewPassword),
  },
  {
    type: 'route',
    name: 'new-password-successful',
    path: '/new-password-successful',
    render: LazyRoute(Lazy.NewPasswordSuccessful),
  },
  {
    type: 'route',
    name: 'login',
    path: '/login',
    render: LazyRoute(Lazy.Login),
  },
  // has to be public because of redirect from email
  {
    type: 'route',
    name: 'admin',
    path: '/admin',
    render: LazyRoute(Lazy.AdminPanel),
  },
];

export const ADMIN_ROUTES: RouterItem[] = [
  {
    type: 'route',
    name: 'settings-instance',
    path: '/administration/instance',
    render: LazyRoute(Lazy.InstanceSettings),
  },
  {
    type: 'route',
    name: 'settings-users',
    path: '/administration/users',
    render: LazyRoute(Lazy.UserSettings),
  },
];

export const MODERATOR_ROUTES: RouterItem[] = [
  {
    type: 'route',
    name: 'settings',
    path: '/administration',
    render: LazyRoute(Lazy.Settings),
  },
  {
    type: 'route',
    name: 'settings-projects',
    path: '/administration/projects',
    render: LazyRoute(Lazy.ProjectSettings),
  },
  {
    type: 'route',
    name: 'settings-general-projects',
    path: '/administration/projects/general',
    render: LazyRoute(Lazy.ProjectGeneralSettings),
  },
  {
    type: 'route',
    name: 'settings-onboarding-projects',
    path: '/administration/projects/onboarding',
    render: LazyRoute(Lazy.ProjectOnboardingSettings),
  },
  {
    type: 'route',
    name: 'settings-tasks',
    path: '/administration/projects/general/:id/tasks',
    render: LazyRoute(Lazy.TaskSettings),
  },
  {
    type: 'route',
    name: 'settings-tasks',
    path: '/administration/projects/onboarding/:id/tasks',
    render: LazyRoute(Lazy.OnboardingTaskSettings),
  },
  {
    type: 'route',
    name: 'settings-groups',
    path: '/administration/groups',
    render: LazyRoute(Lazy.GroupSettings),
  },
  {
    type: 'route',
    name: 'risks',
    path: '/administration/groups/risks',
    render: LazyRoute(Lazy.GroupModuleSettings),
  },
  {
    type: 'route',
    name: 'stakeholders',
    path: '/administration/groups/stakeholders',
    render: LazyRoute(Lazy.GroupModuleSettings),
  },
  {
    type: 'route',
    name: 'lessons-learned',
    path: '/administration/groups/lessons-learned',
    render: LazyRoute(Lazy.GroupModuleSettings),
  },
  {
    type: 'route',
    name: 'onboarding',
    path: '/administration/groups/onboarding',
    render: LazyRoute(Lazy.GroupModuleSettings),
  },
  {
    type: 'route',
    name: 'settings_groups_questions',
    path: '/administration/groups/risks/:id/questions',
    render: LazyRoute(Lazy.QuestionModuleSettings),
  },
  {
    type: 'route',
    name: 'settings_groups_questions',
    path: '/administration/groups/stakeholders/:id/questions',
    render: LazyRoute(Lazy.QuestionModuleSettings),
  },
  {
    type: 'route',
    name: 'settings_groups_questions',
    path: '/administration/groups/lessons-learned/:id/questions',
    render: LazyRoute(Lazy.QuestionModuleSettings),
  },
  {
    type: 'route',
    name: 'settings_groups_questions',
    path: '/administration/groups/onboarding/:id/questions',
    render: LazyRoute(Lazy.QuestionModuleSettings),
  },
];

export const ONBOARDING: RouterItem[] = [
  {
    type: 'route',
    name: 'onboarding',
    path: '/onboarding',
    render: LazyRoute(Lazy.Onboarding),
  },
];

export const MENTOR_ROUTES: RouterItem[] = [
  {
    type: 'route',
    name: 'onboarding',
    path: '/onboarding',
    render: LazyRoute(Lazy.Onboarding),
  },
  {
    type: 'route',
    name: 'settings',
    path: '/administration',
    render: LazyRoute(Lazy.Settings),
  },
  {
    type: 'route',
    name: 'settings-projects',
    path: '/administration/projects',
    render: LazyRoute(Lazy.ProjectSettings),
  },
  {
    type: 'route',
    name: 'settings-onboarding-projects',
    path: '/administration/projects/onboarding',
    render: LazyRoute(Lazy.ProjectOnboardingSettings),
  },
  {
    type: 'route',
    name: 'settings-tasks',
    path: '/administration/projects/onboarding/:id/tasks',
    render: LazyRoute(Lazy.OnboardingTaskSettings),
  },
  {
    type: 'route',
    name: 'settings-groups',
    path: '/administration/groups',
    render: LazyRoute(Lazy.GroupSettings),
  },
  {
    type: 'route',
    name: 'onboarding',
    path: '/administration/groups/onboarding',
    render: LazyRoute(Lazy.GroupModuleSettings),
  },
  {
    type: 'route',
    name: 'settings_groups_questions',
    path: '/administration/groups/onboarding/:id/questions',
    render: LazyRoute(Lazy.QuestionModuleSettings),
  },
];

export const USER_RISKS: RouterItem[] = [
  {
    type: 'route',
    name: 'risks',
    path: '/risks',
    render: LazyRoute(Lazy.Risks),
  },
];

export const USER_STAKEHOLDERS: RouterItem[] = [
  {
    type: 'route',
    name: 'stakeholders',
    path: '/stakeholders',
    render: LazyRoute(Lazy.Stakeholders),
  },
];

export const USER_LESSONS_LEARNED: RouterItem[] = [
  {
    type: 'route',
    name: 'lessons-learned',
    path: '/lessons-learned',
    render: LazyRoute(Lazy.LessonsLearned),
  },
];

export const USER_ROUTES: RouterItem[] = [
  {
    type: 'route',
    name: 'dashboard',
    path: '/dashboard',
    render: LazyRoute(Lazy.Dashboard),
  },
  {
    type: 'route',
    name: 'projects',
    path: '/projects',
    render: LazyRoute(Lazy.Projects),
  },
  {
    type: 'route',
    name: 'project-detail',
    path: '/projects/:idProject',
    render: LazyRoute(Lazy.ProjectDetail),
  },
  {
    type: 'route',
    name: 'task-detail',
    path: '/projects/:idProject/task/:idTask',
    render: LazyRoute(Lazy.TaskDetail),
  },
];

export const ALL_ROUTES: RouterItem[] = [
  ...PUBLIC_ROUTES,
  ...USER_ROUTES,
  ...MODERATOR_ROUTES,
  ...ADMIN_ROUTES,
  ...ONBOARDING,
  ...USER_RISKS,
  ...USER_STAKEHOLDERS,
  ...USER_LESSONS_LEARNED,
];

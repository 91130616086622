import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

type Props = {
  size?: number;
};

const LoadingCircle: FC<Props> = ({ size = 40 }) => (
  <Box position="relative">
    <CircularProgress
      variant="determinate"
      size={size}
      thickness={4}
      value={100}
      sx={{
        color: 'spinnerGreen',
      }}
    />
    <CircularProgress
      variant="indeterminate"
      disableShrink
      size={size}
      thickness={4}
      sx={{
        color: 'primary.main',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
      }}
    />
  </Box>
);

export default LoadingCircle;

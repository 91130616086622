import i18next from 'i18next';
import { useCallback, useEffect } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import { setLocale } from 'yup';

import cz from 'utils/localization/cz';
import en from 'utils/localization/en';
import { EASMultilanguage } from 'utils/types/EASTypes';
import { LocalizationToLanguage } from 'utils/types/enumMaps';

const resources = { en, cz };
const savedLang = localStorage.getItem('wrelayLanguage');

i18next.use(initReactI18next).init({
  resources,
  lng: savedLang ?? 'cz',
  fallbackLng: 'cz',

  ns: Object.keys(resources.cz),
  fallbackNS: 'translation',

  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

export const useLocalizedMultistring = () => {
  const { i18n } = useTranslation();

  const currentLanguage = LocalizationToLanguage[i18n.language];
  const otherLanguage = currentLanguage === 'CZECH' ? 'ENGLISH' : 'CZECH';

  return useCallback(
    (value?: EASMultilanguage) => {
      if (value?.[currentLanguage] && value?.[currentLanguage] !== '') {
        return value?.[currentLanguage] ?? '';
      }

      if (value?.[otherLanguage] && value?.[otherLanguage] !== '') {
        return value?.[otherLanguage] ?? '';
      }
      return '';
    },
    [currentLanguage, otherLanguage],
  );
};

export const useErrorLocale = () => {
  const { t, i18n } = useTranslation('validation_errors');

  useEffect(() => {
    setLocale({
      mixed: {
        required: t('required'),
        notType: t('invalid_value'),
      },
      number: {
        max: ({ max }: { max: number }) => t('num_max', { max }),
        min: ({ min }: { min: number }) => t('num_min', { min }),
      },
      string: {
        max: ({ max }: { max: number }) =>
          t('str_max' as never, { count: max }),
        email: t('invalid_email'),
      },
      array: {
        min: ({ min }: { min: number }) =>
          t('array_min' as never, { count: min }),
      },
    });
  }, [i18n.language, t]);
};

// Map fallback namespace over all other namespaces
type MapFallback<T, Fallback extends keyof T> = {
  [key in keyof T]: T[key] & T[Fallback];
};

declare module 'react-i18next' {
  // eslint-disable-next-line
  interface Resources
    extends MapFallback<typeof resources['cz'], 'translation'> {}
}

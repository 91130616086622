import { Authority, UserPermissions } from 'utils/types/api';

import { RouterItem } from '../LazyRoute';

import {
  PUBLIC_ROUTES,
  USER_ROUTES,
  USER_RISKS,
  USER_STAKEHOLDERS,
  USER_LESSONS_LEARNED,
  ONBOARDING,
  MODERATOR_ROUTES,
  ALL_ROUTES,
  MENTOR_ROUTES,
} from './routesList';

// Get routes based on user authorities
export const getUserRoutes = (authorities?: Authority[]) => {
  // Everyone has at least the PUBLIC_ROUTES
  const SELECTED_ROUTES: RouterItem[] = [...PUBLIC_ROUTES];

  // NO USER SIGNED IN
  if (authorities === undefined || !authorities.length) {
    return SELECTED_ROUTES;
  }

  // SUPER ADMIN
  if (
    authorities.find(a => (a.authority as UserPermissions) === 'SUPER_ADMIN')
  ) {
    return SELECTED_ROUTES;
  }

  // ADMIN
  if (authorities.find(a => (a.authority as UserPermissions) === 'ADMIN')) {
    SELECTED_ROUTES.push(...ALL_ROUTES);
  }

  // MODERATORS
  if (
    authorities.find(
      a =>
        (a.authority as UserPermissions) === 'LESSONS_LEARNED_MODERATOR' ||
        (a.authority as UserPermissions) === 'ONBOARDING_MODERATOR' ||
        (a.authority as UserPermissions) === 'STAKEHOLDERS_MODERATOR' ||
        (a.authority as UserPermissions) === 'RISKS_MODERATOR',
    )
  ) {
    SELECTED_ROUTES.push(
      ...MODERATOR_ROUTES,
      ...USER_ROUTES,
      ...PUBLIC_ROUTES,
      ...USER_RISKS,
      ...USER_STAKEHOLDERS,
      ...USER_LESSONS_LEARNED,
      ...ONBOARDING,
    );
  }

  // NEWBIE
  if (authorities.find(a => (a.authority as UserPermissions) === 'NEWBIE')) {
    SELECTED_ROUTES.push(...ONBOARDING, ...USER_ROUTES);
  }

  // USER_RISKS
  if (
    authorities.find(a => (a.authority as UserPermissions) === 'RISKS_USER')
  ) {
    SELECTED_ROUTES.push(...USER_RISKS, ...USER_ROUTES);
  }

  // USER_STAKEHOLDERS
  if (
    authorities.find(
      a => (a.authority as UserPermissions) === 'STAKEHOLDERS_USER',
    )
  ) {
    SELECTED_ROUTES.push(...USER_STAKEHOLDERS, ...USER_ROUTES);
  }

  // USER_LESSONS_LEARNED
  if (
    authorities.find(
      a => (a.authority as UserPermissions) === 'LESSONS_LEARNED_USER',
    )
  ) {
    SELECTED_ROUTES.push(...USER_LESSONS_LEARNED, ...USER_ROUTES);
  }

  // USER_ONBOARDING - MENTOR
  if (
    authorities.find(
      a => (a.authority as UserPermissions) === 'ONBOARDING_USER',
    )
  ) {
    SELECTED_ROUTES.push(...MENTOR_ROUTES, ...USER_ROUTES);
  }

  return SELECTED_ROUTES;
};

// ROUTES THAT THE USER DOESN'T HAVE ACCESS TO
export const getUnauthorizedRoutes = (selectedRoutes: RouterItem[]) =>
  ALL_ROUTES.filter(x => !selectedRoutes.includes(x));

import { Button, Typography } from '@mui/material';
import { UseQueryResult } from 'react-query';

import Wrapper from 'components/layout/Wrapper';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: UseQueryResult<any, any>;
  height: string | number;
};

const ErrorPage = ({ response, height }: Props) => {
  return (
    <Wrapper height={height} justifyContent="center" alignItems="center">
      <Typography>
        Nastala chyba během získávání dat!
        {response.error
          ? ` (${(response.error as Error)?.message ?? 'unknown error'})`
          : ''}
      </Typography>

      <Typography>Prosím zkuste operaci znovu.</Typography>

      <Button onClick={() => response.refetch()}>Zkusit znovu</Button>
    </Wrapper>
  );
};

export default ErrorPage;

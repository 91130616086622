export default {
  // NOT FOUND
  NotFound: () => import('modules/common/NotFound'),

  // UNAUTHORIZED
  Unauthorized: () => import('modules/common/Unauthorized'),

  // LANDING PAGE
  Landing: () => import('modules/landing-page/LandingPage'),

  // LOGIN
  Login: () => import('modules/auth/LoginPage'),

  // REGISTRATION
  Registration: () => import('modules/auth/RegistrationPage'),
  RegistrationSuccessful: () =>
    import('modules/auth/RegistrationSuccessfulPage'),

  // NEW-PASSWORD
  PasswordRecovery: () => import('modules/auth/PasswordRecoveryPage'),
  PasswordRecoverySuccessful: () =>
    import('modules/auth/PasswordRecoverySuccessfulPage'),
  NewPassword: () => import('modules/auth/NewPasswordPage'),
  NewPasswordSuccessful: () => import('modules/auth/NewPasswordSuccessfulPage'),

  // GENERAL
  Dashboard: () => import('modules/dashboard/Dashboard'),
  LessonsLearned: () => import('modules/lessons-learned/LessonsLearned'),
  Risks: () => import('modules/risks/Risks'),
  Stakeholders: () => import('modules/stakeholders/Stakeholders'),
  Onboarding: () => import('modules/onboarding/Onboarding'),

  // PROJECTS
  Projects: () => import('modules/projects/Projects'),
  ProjectDetail: () => import('modules/projects/ProjectDetail'),
  TaskDetail: () => import('modules/projects/TaskDetail'),

  // SETTINGS
  Settings: () => import('modules/settings/Settings'),

  // Groups
  GroupSettings: () => import('modules/settings/group-settings/GroupSettings'),
  GroupModuleSettings: () =>
    import('modules/settings/group-settings/GroupModuleSettings'),
  QuestionModuleSettings: () =>
    import('modules/settings/group-settings/QuestionModuleSettings'),

  // Projects
  ProjectSettings: () =>
    import('modules/settings/project-settings/ProjectSettings'),
  ProjectGeneralSettings: () =>
    import('modules/settings/project-settings/ProjectGeneralSettings'),
  ProjectOnboardingSettings: () =>
    import('modules/settings/project-settings/ProjectOnboardingSettings'),
  TaskSettings: () => import('modules/settings/project-settings/TaskSettings'),
  OnboardingTaskSettings: () =>
    import('modules/settings/project-settings/OnboardingTaskSettings'),

  // Users
  UserSettings: () => import('modules/settings/user-settings/UserSettings'),

  // Instance
  InstanceSettings: () =>
    import('modules/settings/instance-settings/InstanceSettings'),
  AdminPanel: () => import('modules/admin/AdminPanel'),
};
